.copyright{
    text-align: center;
    padding-bottom: 10px;
}

.james-wilson{
    text-align: left;
    padding-bottom: 10px;
}

@media only screen and (min-width: 768px) {
    .footer-main{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding:0px;
        position: relative;
        width: 100%;
        border-top: 2px black solid;
        margin-bottom:75px;
        padding-bottom:20px;
    }
    
    .footer-main li{
       text-align: center;
    }
    
    .footer-main li:nth-child(1) {
        flex:0.2;
        padding-left:20px;
    }
    .footer-main li:nth-child(1) h3 {
        text-align: start;
    }
    .footer-main li:nth-child(1) p {
        text-align: start;
    }
    
    
    
    .footer-main li:nth-child(2) {
        position: absolute;
          left: 50%;
          transform: translateX(-50%);
    }
    .footer-main li:nth-child(2) a {
        text-decoration: none;
        color: black;
    }
    
    
    
    .footer-main li:nth-child(3) {
        display: flex;
        flex-direction: column;
        padding-right: 50px;
    }
    .footer-main li:nth-child(3) a {
        align-self: flex-start;
        text-decoration: none;
        color: black;
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .footer-main{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding:0px;
        width: 100%;
        border-top: 2px black solid;
        margin-bottom:15px;
    }

    .footer-main li:nth-child(1) p {
        padding-left:20px;
    }

    .footer-main li:nth-child(2) a {
        text-decoration: none;
        color: black;
        font-size:5vw;
    }
    .footer-main li:nth-child(3) a {
       font-size:4vw;
        text-decoration: none;
        color: black;
      
    }

    .footer-main li:nth-child(3) a div {
        font-size:5vw;
        padding-bottom:10px;
        
       
     }
    
}

