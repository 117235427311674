.accordion-section h3 {
    border-bottom: 1px solid #d3d3d3;
    cursor: pointer;
    margin: 0;
    padding: 20px;
    padding-left: 0px;
  }
  
  .accordion-section.active p {
    display: block;
  }

  .main-accordion-wrapper {
    padding-bottom:8vh;

  }