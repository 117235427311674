*{
  font-family: "abadi-mt-condensed-extra-bold-1";
}



@media (min-width: 1700px) {
  .wide-screen-padding {
    padding-left: 20em;
    padding-right: 20em;
  }
  .App {
    text-align: center;
    min-height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
    font-family: Proxima Nova;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .App-header{
 
  }

}

@media (max-width: 767px) {
  .App {
    overflow-x: hidden;
    text-align: center;
    min-height: 100vh;
    margin: 0;
    flex-direction: column;
    font-family: Proxima Nova;
    align-items: center;
    justify-content: center;
  }
  
  .App-header{
    display: flex;
    flex-direction: row;
    min-width: 100%;
    border-bottom: solid 1px black;
    box-shadow: 0px 2px 5px #161212;
    background-color: white;
    padding-top: 2vh !important; 
    min-height: 5vh;
    
    
    
  }
}

@media only screen   
and (min-device-width : 768px)   
and (max-device-width : 1599px) {
.wide-screen-padding {
  padding-left: 2em;
  padding-right: 2em;
}

@media screen 
  and (min-device-width: 1200px) 
  and (max-device-width: 1600px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (min-resolution: 192dpi) { 
    .wide-screen-padding {
      padding-left: 2em;
      padding-right: 2em;
    }

}

}
.copy-style {
  font-size: 1.5em;
    font-family: Proxima Nova;
  }
  
  .landing-app{
  align-items: baseline;
}

.home-page-background {
  background-color: rgb(255, 255, 255);
}