.countdown-timer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 15vh;
  padding-top: 3em;
}

.timer {
  display: flex;
  justify-content: space-between;
  width: 500px;
  margin: 0 auto;
}

.time-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.time-value {
  font-size: 36px;
  font-weight: bold;
  font-family: Proxima Nova;
  color: rgb(0, 0, 0);
  margin-bottom: 0px;
}

.time-label {
  font-size: 18px;
  font-family: Proxima Nova;
  color: black;
}

@media only screen and (max-width: 767px) {
  .countdown-timer {
    margin-bottom: 40px;
  }
}

.container {
  margin-top: 25px;
  margin-bottom: 25px;
}
