@media (min-width: 992px) {
.desktop-header-main-wrapper{
        display: flex;
        flex-direction: row;
        margin-top:15px;
        font-family: "abadi-mt-condensed-extra-bold-1";
}

.main-heading {
    /* flex: 1;
    display: flex;
    padding-left:15em;
    flex-direction: column;
    align-items: center;
    margin:0px; */

    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    flex: 0 1 auto;
    display: flex;
    flex-direction: column;
    margin: 0;

}


.menu-line-large {
    width: 100%;
    border-bottom: 8px solid black;
    margin-bottom:5px;
}

.menu-line-small {
    width: 100%;
    padding-top: 5px;
    border-bottom: 2px solid black;
}

.rivendell-title {
    margin:0px;
    font-family: "abadi-mt-condensed-extra-bold-1";
    font-weight: 800;
    font-size: 5vw;
}

.secret-title{
    margin:0px;
    width: 100%;
    text-align: center;
    font-size: 2vw;
    letter-spacing: 12px;
    font-weight: 100;
}

.date-title {
    flex: 0 1 auto;
    width: 100px;
    height: 100px;
    padding-top: 50px;
    margin-left: auto;
    padding-right: 20px;
}

.tickets-button {
    width:200px;
    height:75px;
    background-color: black;
    color: white;
    font-size:x-large;
    margin-top:2em;
    
}

.tickets-button:hover{
    cursor: pointer;
    background-color: rgb(70, 68, 68);
    color: white;
    
    
}

.tickets-button:active{
    transform: translateY(5px);
    
}

.desktop-burger-menu {
    cursor: pointer;
    
}


}


