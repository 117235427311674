.lineup-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 5vh;
  text-align: center;
}

.artist-names-wrapper {
  /* display: flex;
    flex-direction: column;
    border: 1px black solid;
    padding:100px;
    padding-top:25px;
    background-color: black;
    color:white;
    font-size:5vh;
    padding-bottom:7vh; */
}

.odd {
  /* margin-left:15vw; */
  align-self: flex-end;
}

.full-lineup-copy {
  border: 2px white solid;
  text-align: center;
  padding: 10px;
}

.lineup-photo {
  width: 40vw;
}

@media only screen and (max-width: 767px) {
  .lineup-photo {
    width: 85vw;
  }
}
