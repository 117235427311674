.landing-background-image {
    background-image: url('../misc/rivendell-landing-page.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.landing-main {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 100vh;
}

.landing-heading-copy{
    font-size: 8vw !important;
    color: floralwhite;
    border-width:3px; 
    border-style:solid;
    text-shadow: 2px 4px 4px black;
}

@media (min-width: 1700px){
    .landing-heading-copy{
        font-size: 5vw !important;
        color: floralwhite;
        border-width:3px; 
        border-style:solid;
        text-shadow: 2px 4px 4px black;
    }

}

@media (max-width: 767px) {
    .landing-heading-copy{
        font-size: 3em !important;
        color: floralwhite;
        border-width:3px; 
        border-style:solid;
        text-shadow: 2px 4px 4px black;
        text-align: center;
    }
}


.sub-heading-copy{
    font-size: 2em;
    color: white;
}

.sub-heading-copy-error{
    font-size: 1em;
    color: white;
    border: 2px solid red;
  border-radius: 2px;
  background-color: red;
  max-width:25%;
  align-self: center;
}

.input-box {
    width: 50%;
    height: 40px;
    background-color: white;
    border: 2px solid gray;
    border-radius: 10px;
    font-size: 18px;
    color: gray;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    outline: none;
    margin: 20px auto;
    display: block;

}

.button {
    width: 50%;
  height: 40px;
  background-color: gray;
  border: 2px solid gray;
  border-radius: 10px;
  font-size: 18px;
  color: white;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  outline: none;
  margin: 20px auto;
  display: block;
  cursor: pointer;
}

.button:hover {
    transform: translateY(-3px);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    background-color: rgb(49, 54, 49);
    border: 2px solid rgb(49, 54, 49);
    }

.password-title-wrapper{
    display: flex;
    flex-direction: column;
}