/* EmailForm.css */

@media (max-width: 767px) {
      .email-form {
         
          text-align: center;
          padding: 30px;
          border: 1px solid gray;
          border-radius: 5px;
        }
  }

  @media (min-width: 992px) {
    .email-form {
        margin: 50px auto;
        width:500px;
        text-align: center;
        padding: 30px;
        border: 1px solid gray;
        border-radius: 5px;
      }
  }
  
  .form-title {
    margin-bottom: 50px;
    font-size: 36px;
    font-weight: bold;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .form-group label {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid gray;
  }

  .form-group textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid gray;
    height:18vh;
  }
  
  .submit-button {
    width: 150px;
    padding: 15px;
    background-color: blue;
    color: white;
    border-radius: 5px;
    border: none;
    font-size: 18px
  }  
  
  