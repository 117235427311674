.header-buttons {
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px rgb(225, 219, 219);
    margin-right:2.5em;
    cursor: pointer;
    color:white;
}

.header-buttons:hover {
  color: rgb(91, 88, 88);
}


ul {
    list-style: none;
    
  }

.copy-style {

}

.header-home-main{
width: 100vw !important;
}


.burger-menu {
  display: none;
  cursor: pointer;
}

.burger-menu span {
  display: block;
  height: 2px;
  width: 30px;
  margin: 6px 0;
  background-color: #000;
}

  .burger-menu {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  


.mobile-nav-dropdown {
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    margin-top:0px;
    margin-bottom: 0px;
    padding-left:0px;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    width:100%;
   

}

.mobile-nav-dropdown:active {
  transition: all 0.3s ease-in-out;
  
 

}

.mobile-nav-dropdown a {
  text-decoration: none;
}

.nav-list-item {
    border-top: 2px solid gray;
    border-radius: 10px;
    margin:5px;
    padding:10px;
    width:80vw;
    
}

.mobile-nav-bar-wrapper{
    color: white;
    display: flex;
    gap: 2vh;
    margin-right: 15vw;
    margin-top: 0.5em;
    padding-bottom: 1em;
    padding-left: 5vw;
    padding-top: 1em;
    text-decoration: none;
    width: 83%;
    box-shadow: 1px 4px 4px grey;
    background-color: black;
    font-size: 1rem;

}

.mobile-nav-item {

}


.mobile-ticket-button{
color: white;
background-color: black;
margin-left:1rem;
padding-left:1rem;
padding-right:1rem;
margin-bottom:3vw;
min-height: 7vh;
min-width: 22vw;
}

.rivendell-logo-header{
  height: 100px;
  width: 100px;
  left: 50%;
  position: sticky;
  transform: translateX(-50%) translateY(-2px);
}