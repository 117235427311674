.about-main-wrapper{
  display: flex;
  flex-direction: column;
  padding-left: 5vw;
  padding-right: 5vw;
}


.about-copy-wrapper > h3 {
    text-align: center;
    font-weight:100;
}



@media only screen and (max-width: 767px) {
  .about-copy-wrapper > h3{
    text-align: left;
    font-weight:100;
   
}

.about-copy-wrapper >h2 {
  padding-left:0.8rem
}


}