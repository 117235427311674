.home-main{
    display: flex;
    flex-direction: column;
    min-height:100vh;
}


.home-body-wrapper {
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 992px) {
  
    .ticket-button {
      background-color: #0e1334;
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
      cursor: pointer;
      border-radius: 5px;
      }
  }

  @media only screen and (max-width: 767px) {
    /* CSS styles to be applied only on mobile devices */
    /* For example, adjust font size, padding, etc. */
     .home-main{
      font-size: 14px;
      
    }
  }

  .ticket-button {
    background-color: #0e1334;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
    }

    .ticket-button:active {
        text-shadow: 2px 4px 4px grey;
    box-shadow: 2px 2px 5px #3043bb;
    transform: translateY(5px);
    }