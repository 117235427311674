/* MaintenanceMessage.css */

.maintenance-container {
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    margin-top: 100px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .maintenance-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .maintenance-text {
    font-size: 16px;
    color: #666;
  }
  