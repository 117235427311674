@media only screen and (min-width: 992px){
     .photos-main-wrapper {
        padding-top:10vh;

}
}




@media only screen and (max-width: 767px) {

.photos-main-wrapper{
        
        padding: 1em;;

}
}