@media (min-width: 992px) {


    .button-tiles-wrapper{
    display:flex;
    flex-direction: row;    
    gap: 20px;
    margin-top:50px
    }

    .tile-wrapper{
        width:33%;
        position: relative;
  text-align: center;
  color: white;
    }
    .tile-wrapper:hover{
        opacity: 60%;
        cursor: pointer;
    }

    .tile-wrapper > img{
        width:100%;
        
    }

    .middle-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size:2vw;
    }
        
    }

    @media only screen and (max-width: 767px) {
        .button-tiles-wrapper{
            display:flex;
            flex-direction: column;    
            gap: 20px;
            margin-top:50px
            }
        
            .tile-wrapper{
                position: relative;
                text-align: center;
                color: white;
            }
            .tile-wrapper:hover{
                opacity: 60%;
                cursor: pointer;
            }
        
            .tile-wrapper > img{
                width:100%;
                
            }
        
            .middle-text {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size:8vw;
            }

    }