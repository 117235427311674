 @media (min-width: 992px) {
    .carousel {
      min-height: 60vh;
      margin-bottom: 10px;
      margin-top: 10px;
      position: relative;
    }
    .slide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  @media (max-width: 767px) {
    .carousel {
      height: 300px;
      position: relative;
    }
    .slide img {
      height: auto;
    object-fit: cover;
    width: 100%;
    
    }
  }
  
  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease;
  }
  
  .slide.active {
    opacity: 1;
  }
  
  

  

  
  .prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2em;
    padding: 2px 5px;
    background-color: rgba(199, 199, 199, 0.7);
    border-radius: 50%;
    cursor: pointer;
  }
  
  .prev {
    left: 20px;
  }
  
  .next {
    right: 20px;
  }
  