.tickets-main{
    display: flex;
    flex-direction: column;
    min-height:100vh;
}

.heading-title{
   
}

.tickets-main-wrapper {
    padding-left: 20px;
    padding-right: 20px;
}

@media only screen and (min-width: 992px) {
    .tickets-main-wrapper {
        margin-top:5em;
        padding-left: 20px;
        padding-right: 20px;
    }
}